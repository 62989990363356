import React, { useState } from 'react';
import { Menu, X, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import logo1 from '../asset/GitFork.png'; // Update with your logo's path



export function Navbar({ onSignIn, onGetStarted }) {
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    {
      title: 'Products',
      items: ['Learning Platform', 'Virtual Classroom', 'Assessment Tools', 'Analytics Dashboard']
    },
    {
      title: 'Solutions',
      items: ['For Universities', 'For Businesses', 'For Startups', 'For Students']
    },
    {
      title: 'Resources',
      items: ['Documentation', 'Blog', 'Community', 'Support']
    },
    {
      title: 'Pricing',
      items: ['Individual Plans', 'Team Plans', 'Enterprise', 'Compare Plans']
    }
  ];

  const handleMobileMenuClick = (action) => {
    setIsOpen(false);
    console.log('Navigating to Sign In1'); // Debugging line

    if (action === 'signin') {
      console.log('Navigating to Sign In'); // Debugging line

      // onSignIn();
      navigate('/signin'); // Navigate to the Sign-In page

    } else if (action === 'getstarted') {
      onGetStarted();
    }
  };

  return (
    <nav className="bg-gray-900/95 backdrop-blur-lg fixed w-full z-50 border-b border-gray-800">
    {/* <nav className="bg-black/95 backdrop-blur-lg fixed w-full z-50 border-b border-gray-800 shadow-neon"> */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          
          <div className="flex items-center">
            <a href="/" className="text-2xl font-bold text-white flex items-center">
              <img src={logo1} alt="HackerFork Logo" className="h-8 w-8 mr-2" />
              <span>
                Hacker<span className="text-blue-500">Fork</span>
              </span>
            </a>
          </div>

          {/* Desktop Navigation */}
          {/* <div className="hidden md:flex items-center space-x-8">
            {navItems.map((item) => (
              <div key={item.title} className="relative group">
                <button className="text-gray-300 hover:text-white flex items-center gap-1 cursor-pointer">
                  {item.title}
                  <ChevronDown size={16} />
                </button>
                <div className="absolute top-full -left-4 hidden group-hover:block pt-2">
                  <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700 w-48 py-2">
                    {item.items.map((subItem) => (
                      <a
                        key={subItem}
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white cursor-pointer"
                      >
                        {subItem}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div> */}

          <div className="hidden md:flex items-center space-x-4">
            {/* <button 
              onClick={onSignIn}
              className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors cursor-pointer"

              // className="text-gray-300 hover:text-white px-3 py-2 cursor-pointer"
            >
              Sign In
            </button> */}
            {/* <button 
              onClick={onGetStarted}
              className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors cursor-pointer"
            >
              Get Started
            </button> */}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-300 hover:text-white cursor-pointer"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden"
          >
            {/* <div className="px-2 pt-2 pb-3 space-y-1 bg-gray-800">
              {navItems.map((item) => (
                <div key={item.title} className="space-y-1">
                  <button className="w-full text-left px-3 py-2 text-base font-medium text-white hover:bg-gray-700 rounded-md cursor-pointer">
                    {item.title}
                  </button>
                  <div className="pl-4 space-y-1">
                    {item.items.map((subItem) => (

                      
                      <a
                        key={subItem}
                        href=""
                        className="block px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white rounded-md cursor-pointer"
                      >
                        {subItem}
                      </a>
                    ))}
                  </div>
                </div>
              ))}
              <div className="pt-4 space-y-2">
                <button 
                  onClick={() => handleMobileMenuClick('signin')}
                  className="w-full text-left px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 rounded-md cursor-pointer"
                >
                  Sign In
                </button>
                <button 
                  onClick={() => handleMobileMenuClick('getstarted')}
                  className="w-full px-3 py-2 text-base font-medium bg-blue-600 text-white rounded-md hover:bg-blue-700 cursor-pointer"
                >
                  Get Started
                </button>
              </div>
            </div> */}
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}