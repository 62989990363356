import React from 'react';
import { Navbar } from './components/Navbar.js';
import { Footer } from './components/Footer.js';
import Main from './components/Main.js';
import {SignInPage} from './components/SignInPage.js';
import {SignUpPage} from './components/SignUpPage.js';
import Quiz from './components/Quiz.js';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col">
      <Router>
       <Navbar />
       <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* <Route path="/quiz/:language" element={<Quiz />} /> */}
          {/* <Route path="/quiz" element={<Quiz/>} /> */}

        </Routes>
       <Footer />
      </Router>
    </div>
  );
}

export default App;


// import React, { useState } from 'react';
// import { Code2 } from 'lucide-react';
// import Quiz from './components/Quiz';
// import LanguageSelection from './components/LanguageSelection';

// function App() {
//   const [selectedLanguage, setSelectedLanguage] = useState(null);

//   const languages = [
//     {
//       id: 'react',
//       name: 'React JS',
//       icon: Code2,
//       color: 'bg-blue-500',
//       hoverColor: 'hover:bg-blue-600',
//       description: 'Test your React.js knowledge',
//     },
//     {
//       id: 'ruby',
//       name: 'Ruby',
//       icon: Code2,
//       color: 'bg-red-500',
//       hoverColor: 'hover:bg-red-600',
//       description: 'Challenge yourself with Ruby concepts',
//     },
//     {
//       id: 'javascript',
//       name: 'JavaScript',
//       icon: Code2,
//       color: 'bg-yellow-500',
//       hoverColor: 'hover:bg-yellow-600',
//       description: 'Master JavaScript fundamentals',
//     },
//   ];

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
//       <div className="container mx-auto px-4 py-8">
//         <header className="text-center mb-12">
//           <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
//             Programming Quiz Challenge
//           </h1>
//           <p className="text-gray-300">Test your knowledge in different programming languages</p>
//         </header>

//         {selectedLanguage ? (
//           <Quiz 
//             language={selectedLanguage} 
//             onReset={() => setSelectedLanguage(null)}
//           />
//         ) : (
//           <LanguageSelection 
//             languages={languages}
//             onSelect={setSelectedLanguage}
//           />
//         )}
//       </div>
//     </div>
//   );
// }

// export default App;