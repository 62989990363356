import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

function LanguageSelection({ languages, onSelect }) {
    const navigate = useNavigate();

  return (
    <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
      {languages.map((lang) => {
        const Icon = lang.icon;
        return (
          <button
            key={lang.id}
            onClick={() => navigate(lang.route)}
            // onClick={() => onSelect(lang.id)}
            className={`${lang.color} ${lang.hoverColor} transform hover:scale-105 transition-all duration-300 rounded-xl p-8 text-white shadow-xl flex flex-col items-center justify-center gap-4 group`}
          >
            <Icon size={48} className="group-hover:animate-bounce" />
            <h2 className="text-2xl font-bold">{lang.name}</h2>
            <p className="text-center text-sm opacity-90">{lang.description}</p>
          </button>
        );
      })}
    </div>
  );
}

LanguageSelection.propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
      color: PropTypes.string.isRequired,
      hoverColor: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default LanguageSelection;