import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Users, Star } from 'lucide-react';

export function CourseCard({ title, description, duration, students, rating, image, delay }) {
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay }}
      className="bg-gray-900/50 backdrop-blur-lg rounded-xl overflow-hidden hover:shadow-2xl hover:shadow-blue-500/20 transition-all duration-300 border border-gray-800"
    >
      <div className="relative h-48">
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent" />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <p className="text-gray-400 mb-4">{description}</p>
        <div className="flex items-center justify-between text-sm text-gray-400">
          <div className="flex items-center gap-2">
            <Clock size={16} className="text-blue-400" />
            {duration}
          </div>
          <div className="flex items-center gap-2">
            <Users size={16} className="text-blue-400" />
            {students}
          </div>
          <div className="flex items-center gap-2">
            <Star size={16} className="text-yellow-400" fill="currentColor" />
            {rating}
          </div>
        </div>
      </div>
    </motion.div>
  );
}