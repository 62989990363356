import React, {useState} from 'react'
import { motion } from 'framer-motion';
import { Hero } from './Hero.js';
import { CourseCard } from './CourseCard.js';
import { BookOpen, Users, Trophy, ArrowRight } from 'lucide-react';
import { Code2 } from 'lucide-react';
import Quiz from './Quiz';
import LanguageSelection from './LanguageSelection';
import { useNavigate } from 'react-router-dom';


const Main = () => {
    const navigate = useNavigate();

    const stats = [
        { icon: BookOpen, label: 'Courses', value: '200+' },
        // { icon: Users, label: 'Students', value: '50,000+' },
        { icon: Users, label: 'Students', value: '100+' },
        { icon: Trophy, label: 'Success Rate', value: '95%' },
      ];
    
      const courses = [
        {
          title: 'Advanced Machine Learning',
          description: 'Master the fundamentals of ML and AI with hands-on projects',
          duration: '12 weeks',
          students: 1500,
          rating: 4.9,
          image: 'https://images.unsplash.com/photo-1555949963-aa79dcee981c?auto=format&fit=crop&q=80',
        },
        {
          title: 'Full Stack Development',
          description: 'Build modern web applications from front to back',
          duration: '16 weeks',
          students: 2300,
          rating: 4.8,
          image: 'https://images.unsplash.com/photo-1627398242454-45a1465c2479?auto=format&fit=crop&q=80',
        },
        {
          title: 'Blockchain Technology',
          description: 'Explore the world of Web3 and cryptocurrency',
          duration: '10 weeks',
          students: 1200,
          rating: 4.7,
          image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80',
        },
      ];

      const [selectedLanguage, setSelectedLanguage] = useState(null);

  const languages = [
    {
      id: 'react',
      name: 'React JS',
      icon: Code2,
      color: 'bg-blue-500',
      hoverColor: 'hover:bg-blue-600',
      description: 'Test your React.js knowledge',
      route: '/quiz/javascript'
    },
    {
      id: 'ruby',
      name: 'Ruby',
      icon: Code2,
      color: 'bg-red-500',
      hoverColor: 'hover:bg-red-600',
      description: 'Challenge yourself with Ruby concepts',
      route: '/quiz/javascript'
    },
    {
      id: 'javascript',
      name: 'JavaScript',
      icon: Code2,
      color: 'bg-yellow-500',
      hoverColor: 'hover:bg-yellow-600',
      description: 'Master JavaScript fundamentals',
      route: '/quiz/javascript'
    },
    {
        id: 'java',
        name: 'Java',
        icon: Code2,
        color: 'bg-blue-500',
        hoverColor: 'hover:bg-blue-600',
        description: 'Test your React.js knowledge',
        route: '/quiz/javascript'
      },
      {
        id: 'go',
        name: 'Go',
        icon: Code2,
        color: 'bg-red-500',
        hoverColor: 'hover:bg-red-600',
        description: 'Challenge yourself with Ruby concepts',
        route: '/quiz/javascript'
      },
      {
        id: 'python',
        name: 'Python',
        icon: Code2,
        color: 'bg-yellow-500',
        hoverColor: 'hover:bg-yellow-600',
        description: 'Master JavaScript fundamentals',
        route: '/quiz/javascript'
      },

      {
        id: 'nodejs',
        name: 'Node Js',
        icon: Code2,
        color: 'bg-blue-500',
        hoverColor: 'hover:bg-blue-600',
        description: 'Test your React.js knowledge',
        route: '/quiz/javascript'
      },
      {
        id: 'html',
        name: 'HTML',
        icon: Code2,
        color: 'bg-red-500',
        hoverColor: 'hover:bg-red-600',
        description: 'Challenge yourself with Ruby concepts',
        route: '/quiz/javascript'
      },
      {
        id: 'css',
        name: 'CSS',
        icon: Code2,
        color: 'bg-yellow-500',
        hoverColor: 'hover:bg-yellow-600',
        description: 'Master JavaScript fundamentals',
        route: '/quiz/javascript'
      },
  ];

    
  return (
    <div>
      
      <div className="pt-16"> {/* Add padding top to account for fixed navbar */}
        <Hero />

    {/* comment quiz section */}
        {/* <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white"> */}
      {/* <div className="container mx-auto px-4 py-8">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
            Programming Quiz Challenge
          </h1>
          <p className="text-gray-300">Test your knowledge in different programming languages</p>
        </header>

        {selectedLanguage ? (
          <Quiz 
            language={selectedLanguage} 
            onReset={() => setSelectedLanguage(null)}
          />
        ) : (
          <LanguageSelection 
            languages={languages}
            onSelect={setSelectedLanguage}
          />
        )}
      </div> */}
    {/* </div> */}

        
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-6 text-center border border-gray-700"
              >
                <stat.icon className="w-8 h-8 mx-auto mb-4 text-blue-400" />
                <h3 className="text-3xl font-bold mb-2">{stat.value}</h3>
                <p className="text-gray-400">{stat.label}</p>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl font-bold mb-4">Featured Courses</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Explore our most popular courses and start your journey towards mastery
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {courses.map((course, index) => (
              <CourseCard key={course.title} {...course} delay={index * 0.2} />
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center"
          >
            {/* <button className="group px-8 py-3 bg-blue-600 text-white rounded-full font-semibold hover:bg-blue-700 transition-all duration-300 flex items-center gap-2 mx-auto">
              View All Courses
              <ArrowRight className="group-hover:translate-x-1 transition-transform duration-300" />
            </button> */}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Main
